import React, { createContext, useContext, forwardRef } from "react";

import { BaseBoxProps, Box } from "Atoms";
import { UseEditableProps, UseEditableReturn, useEditable } from "./hooks";
import { runIfFn } from "FunctionUtils";
import { MaybeRenderProp } from "Types";

type RenderProps = Pick<UseEditableReturn, "isEditing" | "onSubmit" | "onCancel" | "onEdit">;

interface BaseEditableProps extends Omit<BaseBoxProps, "onChange" | "value" | "defaultValue" | "onSubmit"> {}
//@ts-ignore //TODO: Not typescripted properly
export interface EditableProps extends BaseEditableProps, Omit<UseEditableProps, "children"> {
    children: MaybeRenderProp<RenderProps>;
}

const EditableContext = createContext<Omit<UseEditableReturn, "rest">>(null as any);

export const useEditableContext = () => {
    return useContext(EditableContext);
};

export const Editable: React.FC<EditableProps> = forwardRef((props, ref) => {
    const { rest, ...context } = useEditable(props);

    const { isEditing, onSubmit, onCancel, onEdit } = context;

    const children = runIfFn(props.children, {
        isEditing,
        onSubmit,
        onCancel,
        onEdit
    });

    return (
        <EditableContext.Provider value={context}>
            <Box ref={ref} w="full" {...rest}>
                {children}
            </Box>
        </EditableContext.Provider>
    );
});

export const commonStyles = {
    fontSize: "inherit",
    fontWeight: "normal",
    textAlign: "inherit",
    bg: "transparent"
};

export default Editable;
