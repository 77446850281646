import React from "react";

import { Box, BaseBoxProps } from "../Box/Box";

// Creating shorthands from SS flex props
interface IFlex {
    align?: BaseBoxProps["alignItems"];
    direction?: BaseBoxProps["flexDirection"];
    wrap?: BaseBoxProps["flexWrap"];
    justify?: BaseBoxProps["justifyContent"];
}

export type FlexProps = IFlex & BaseBoxProps;

export const Flex: React.FC<FlexProps> = React.forwardRef(
    ({ align, color, direction, wrap, justify, height = "100%", children, ...props }, ref) => (
        <Box
            display="flex"
            alignItems={align}
            flexDirection={direction}
            flexWrap={wrap}
            justifyContent={justify}
            height={height}
            color={color as any}
            ref={ref}
            {...props}
        >
            {children}
        </Box>
    )
);
