import { createGlobalStyle } from "styled-components";

/*
  Since we are still using semantic's global styles we don't want
  our new styles to collide and mess with other UI.
  Therefor we will add tailwinds's styles bit by bit
*/

const tailwindCss = `
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button {
    background: transparent;
    padding: 0;
  }

  button,
  [role="button"] {
    cursor: pointer;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }
`;

const ownGlobalStyles = `
  * {
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif;
  }

  html, body {
    background: #fff;
    line-height: 1.7;
    text-rendering: optimizelegibility;
    touch-action: manipulation;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
  }

`;

export const GlobalStyles = createGlobalStyle`
  ${ownGlobalStyles}
  ${tailwindCss}
`;
