import styled from "styled-components";
import { HTMLAttributes } from "react";
interface GridDefinition {
    templateAreas: string;
    templateRows: string;
    templateColumns: string;
    gridGap: string;
    justifyItems: string;
    justifyContent: string;
}

interface GridAreaDefinition {
    name: string;
    alignSelf?: string;
    justifySelf?: string;
}

type GridProps = {
    display: string;
    templateAreas: string;
    templateRows: string;
    templateColumns: string;
    fullscreen: boolean;
    gap: string;
    columnGap: string;
    alignItems: string;
    justifyItems: string;
    height: string;
} & HTMLAttributes<HTMLDivElement>;

interface GridAreaProps extends GridAreaDefinition {
    xs?: GridAreaDefinition;
    sm?: GridAreaDefinition;
    md?: GridAreaDefinition;
    lg?: GridAreaDefinition;
}

type GridLayoutProps = {
    xs: Partial<GridDefinition>;
    sm: Partial<GridDefinition>;
    md: Partial<GridDefinition>;
    lg: Partial<GridDefinition>;
};

/**
 * @deprecated This component is deprecated. Use NewGrid instead.
 */
export const Grid = styled.div<Partial<GridProps>>`
    display: ${props => (props.display ? props.display : "grid")};
    grid-template-areas: ${props => props.templateAreas};
    grid-template-rows: ${props => props.templateRows};
    grid-template-columns: ${props => props.templateColumns};
    grid-column-gap: ${props => props.columnGap};
    align-items: ${props => props.alignItems};
    justify-items: ${props => props.justifyItems};
    grid-gap: ${props => props.gap};
    height: ${props => (props.fullscreen ? "100vh" : props.height ? props.height : "100%")};
    width: 100%;
`;

/**
 * @deprecated This component is deprecated. Use NewGrid instead.
 */
export const GridLayout = styled(Grid)<Partial<GridLayoutProps>>`
    @media (min-width: 250px) {
        grid-template-areas: ${props => props.xs && props.xs.templateAreas};
        grid-gap: ${props => props.xs && props.xs.gridGap};
        grid-template-rows: ${props => props.xs && props.xs.templateRows};
        grid-template-columns: ${props => props.xs && props.xs.templateColumns};
        justify-items: ${props => props.xs && props.xs.justifyItems};
    }

    @media (min-width: 450px) {
        grid-template-areas: ${props => props.sm && props.sm.templateAreas};
        grid-gap: ${props => props.sm && props.sm.gridGap};
        grid-template-rows: ${props => props.sm && props.sm.templateRows};
        grid-template-columns: ${props => props.sm && props.sm.templateColumns};
        justify-items: ${props => props.sm && props.sm.justifyItems};
    }

    @media (min-width: 768px) {
        grid-template-areas: ${props => props.md && props.md.templateAreas};
        grid-gap: ${props => props.md && props.md.gridGap};
        grid-template-rows: ${props => props.md && props.md.templateRows};
        grid-template-columns: ${props => props.md && props.md.templateColumns};
        justify-items: ${props => props.md && props.md.justifyItems};
    }

    @media (min-width: 1200px) {
        grid-template-areas: ${props => props.lg && props.lg.templateAreas};
        grid-template-rows: ${props => props.lg && props.lg.templateRows};
        grid-template-columns: ${props => props.lg && props.lg.templateColumns};
        justify-items: ${props => props.lg && props.lg.justifyItems};
        justify-content: ${props => props.lg && props.lg.justifyContent};
        grid-gap: ${props => props.lg && props.lg.gridGap};
    }
`;

/**
 * @deprecated This component is deprecated. Use NewGrid instead.
 */
export const GridArea = styled.div<GridAreaProps>`
    grid-area: ${props => props.name};
    align-self: ${props => props.alignSelf};
    justify-self: ${props => props.justifySelf};

    @media (min-width: 250px) {
        align-self: ${props => props.xs && props.xs.alignSelf};
        grid-area: ${props => props.xs && props.xs.name};
        justify-self: ${props => props.xs && props.xs.justifySelf};
    }

    @media (min-width: 450px) {
        align-self: ${props => props.sm && props.sm.alignSelf};
        grid-area: ${props => props.sm && props.sm.name};
        justify-self: ${props => props.sm && props.sm.justifySelf};
    }

    @media (min-width: 768px) {
        align-self: ${props => props.md && props.md.alignSelf};
        grid-area: ${props => props.md && props.md.name};
        justify-self: ${props => props.md && props.md.justifySelf};
    }

    @media (min-width: 1200px) {
        align-self: ${props => props.lg && props.lg.alignSelf};
        grid-area: ${props => props.lg && props.lg.name};
        justify-self: ${props => props.lg && props.lg.justifySelf};
    }
`;
