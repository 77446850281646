import { useCallback, useEffect, useRef, useState } from "react";

import { useCallbackRef, useControllableState, useEnhancedEffect, useFocusOnPointerDown, useUpdateEffect } from "CoreHooks";
import { callAllHandlers, mergeRefs } from "FunctionUtils";

export type UseEditableProps = {
    value?: string;
    defaultValue?: string;
    isDisabled?: boolean;
    startWithEditView?: boolean;
    isPreviewFocusable?: boolean;
    submitOnBlur?: boolean;
    onChange?: (nextValue: string) => void;
    onCancel?: (previousValue: string) => void;
    onSubmit?: (nextValue: string) => void;
    onEdit?: () => void;
    selectAllOnFocus?: boolean;
    placeholder?: string;
};

function contains(parent: HTMLElement | null, child: HTMLElement) {
    if (!parent) return false;
    return parent === child || parent.contains(child);
}

export const useEditable = (props: UseEditableProps) => {
    const {
        onChange: onChangeProp,
        onCancel: onCancelProp,
        onSubmit: onSubmitProp,
        value: valueProp,
        isDisabled,
        defaultValue,
        startWithEditView,
        isPreviewFocusable = true,
        submitOnBlur = true,
        selectAllOnFocus = true,
        placeholder,
        onEdit: onEditCallback,
        ...rest
    } = props;

    const onEditProp = useCallbackRef(onEditCallback);

    const defaultIsEditing = Boolean(startWithEditView && !isDisabled);

    const [isEditing, setIsEditing] = useState(defaultIsEditing);

    const [value, setValue] = useControllableState({
        defaultValue: defaultValue ?? "",
        value: valueProp,
        onChange: onChangeProp
    });

    const [previousValue, setPreviousValue] = useState(value);

    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const previewRef = useRef<any>(null);

    const editButtonRef = useRef<HTMLButtonElement>(null);
    const cancelButtonRef = useRef<HTMLElement>(null);
    const submitButtonRef = useRef<HTMLElement>(null);

    useFocusOnPointerDown({
        ref: inputRef,
        enabled: isEditing,
        elements: [cancelButtonRef, submitButtonRef]
    });

    const isInteractive = !isEditing && !isDisabled;

    useEnhancedEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();

            if (selectAllOnFocus) {
                inputRef.current?.select();
            }
        }
    }, []);

    useUpdateEffect(() => {
        if (!isEditing) {
            editButtonRef.current?.focus();
            return;
        }

        inputRef.current?.focus();

        if (selectAllOnFocus) {
            inputRef.current?.focus();
        }

        onEditProp?.();
    }, [isEditing, onEditProp, selectAllOnFocus]);

    const onEdit = useCallback(() => {
        if (isInteractive) {
            setIsEditing(true);
        }
    }, [isInteractive]);

    const onUpdatePrevValue = useCallback(() => {
        setPreviousValue(value);
    }, [value]);

    const onCancel = useCallback(() => {
        setIsEditing(false);
        setValue(previousValue);
        onCancelProp?.(previousValue);
    }, [onCancelProp, setValue, previousValue]);

    const onSubmit = useCallback(() => {
        setIsEditing(false);
        setPreviousValue(value);
        onSubmitProp?.(value);
    }, [value, onSubmitProp]);

    useEffect(() => {
        if (isEditing) {
            return;
        }
        // https://bugzilla.mozilla.org/show_bug.cgi  ?id=559561
        const inputEl = inputRef.current;
        if (inputEl?.ownerDocument.activeElement === inputEl) {
            inputEl?.blur();
        }
    }, [isEditing]);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(event.currentTarget.value);
        },
        [setValue]
    );

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            const eventKey = event.key;

            const keyMap: Record<string, React.KeyboardEventHandler> = {
                Escape: onCancel,
                Enter: event => {
                    if (!event.shiftKey && !event.metaKey) {
                        onSubmit();
                    }
                }
            };

            const action = keyMap[eventKey];

            if (action) {
                event.preventDefault();
                action(event);
            }
        },
        [onCancel, onSubmit]
    );

    const isValueEmpty = value.length === 0;

    const onBlur = useCallback(
        (event: React.FocusEvent) => {
            if (!isEditing) return;
            const doc = event.currentTarget.ownerDocument;
            const relatedTarget = (event.relatedTarget ?? doc.activeElement) as HTMLElement;
            const targetIsCancel = contains(cancelButtonRef.current, relatedTarget);
            const targetIsSubmit = contains(submitButtonRef.current, relatedTarget);
            const isValidBlur = !targetIsCancel && !targetIsSubmit;

            if (isValidBlur) {
                if (submitOnBlur) {
                    onSubmit();
                } else {
                    onCancel();
                }
            }
        },
        [submitOnBlur, onSubmit, onCancel, isEditing]
    );

    const getPreviewProps = useCallback(
        (props = {}, ref = null) => {
            const tabIndex = isInteractive && isPreviewFocusable ? 0 : undefined;
            return {
                ...props,
                ref: mergeRefs(ref, previewRef),
                children: isValueEmpty ? placeholder : value,
                display: isEditing ? "none" : "block",
                tabIndex,
                //@ts-ignore //TODO: Not typescripted properly
                onFocus: callAllHandlers(props.onFocus, onEdit, onUpdatePrevValue)
            };
        },
        [
            isDisabled,
            isEditing,
            isInteractive,
            isPreviewFocusable,
            isValueEmpty,
            onEdit,
            onUpdatePrevValue,
            placeholder,
            value
        ]
    );

    const getInputProps = useCallback(
        (props = {}, ref = null) => ({
            ...props,
            display: isEditing ? "block" : "none",
            placeholder,
            ref: mergeRefs(ref, inputRef),
            disabled: isDisabled,
            value,
            //@ts-ignore //TODO: Not typescripted properly
            onBlur: callAllHandlers(props.onBlur, onBlur),
            //@ts-ignore //TODO: Not typescripted properly
            onChange: callAllHandlers(props.onChange, onChange),
            //@ts-ignore //TODO: Not typescripted properly
            onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown),
            //@ts-ignore //TODO: Not typescripted properly
            onFocus: callAllHandlers(props.onFocus, onUpdatePrevValue)
        }),
        [isDisabled, isEditing, onBlur, onChange, onKeyDown, onUpdatePrevValue, placeholder, value]
    );

    const getEditButtonProps = useCallback(
        (props = {}, ref = null) => ({
            ...props,
            type: "button",
            //@ts-ignore //TODO: Not typescripted properly
            onClick: callAllHandlers(props.onClick, onEdit),
            ref: mergeRefs(ref, editButtonRef),
            disabled: isDisabled
        }),
        [onEdit, isDisabled]
    );

    const getSubmitButtonProps = useCallback(
        (props = {}, ref = null) => ({
            ...props,
            ref: mergeRefs(submitButtonRef, ref),
            type: "button",
            //@ts-ignore //TODO: Not typescripted properly
            onClick: callAllHandlers(props.onClick, onSubmit),
            disabled: isDisabled
        }),
        [onSubmit, isDisabled]
    );

    const getCancelButtonProps = useCallback(
        (props = {}, ref = null) => ({
            ...props,
            ref: mergeRefs(cancelButtonRef, ref),
            type: "button",
            //@ts-ignore //TODO: Not typescripted properly
            onClick: callAllHandlers(props.onClick, onCancel),
            disabled: isDisabled
        }),
        [onCancel, isDisabled]
    );

    return {
        isEditing,
        isDisabled,
        isValueEmpty,
        value,
        onEdit,
        onCancel,
        onSubmit,
        getPreviewProps,
        getInputProps,
        getEditButtonProps,
        getSubmitButtonProps,
        getCancelButtonProps,
        rest
    };
};

export type UseEditableReturn = ReturnType<typeof useEditable>;
