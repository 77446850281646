import React from "react";

import { Text } from "../Text/Text";
import { BaseBoxProps } from "../Box/Box";

export const FormHelperText: React.FC<BaseBoxProps> = ({ children, ...props }) => {
    return (
        <Text mt={2} color="gray.600" lineHeight="normal" fontSize="sm" {...props}>
            {children}
        </Text>
    );
};
