import React, { forwardRef } from "react";

import { PseudoBox, PseudoBoxProps } from "Atoms";
import { commonStyles, useEditableContext } from "../Editable";

export type EditableInputProps = PseudoBoxProps;

export const EditableInput: React.FC<EditableInputProps> = forwardRef((props, ref) => {
    const { getInputProps } = useEditableContext();

    //@ts-ignore //TODO: Not typescripted properly
    const inputProps = getInputProps(props, ref);

    return (
        <>
            {/** @ts-ignore */}
            <PseudoBox
                //@ts-ignore //TODO: Not typescripted properly
                ref={ref}
                as="input"
                outline="none"
                border="none"
                w="full"
                transition="all 0.2s"
                rounded="lg"
                _focus={{ boxShadow: "outline" }}
                {...commonStyles}
                {...inputProps}
            />
        </>
    );
});
