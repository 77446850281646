import React from "react";

import { Flex, BaseBoxProps, Text } from "../";

export const FormErrorMessage: React.FC<BaseBoxProps> = ({ children, ...props }) => {
    return (
        <Flex h="auto" color="red.500" mt={2} align="center" {...props}>
            <Text data-test="text-form-error-message" lineHeight="normal" fontSize="sm">
                {children}
            </Text>
        </Flex>
    );
};
