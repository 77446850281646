import React from "react";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";

import { PseudoBox, PseudoBoxProps, Box } from "Atoms";
import { IconType } from "@react-icons/all-files";

interface ICloseButton {
    size: "touch" | "lg" | "md" | "sm";
    isDisabled: boolean;
    color: string;
    closeButtonIcon: React.ElementType<IconType>;
}

export type CloseButtonProps = Partial<ICloseButton> & PseudoBoxProps;

const styles = {
    top: "12px",
    right: "12px",
    transition: "all 0.3s",
    _hover: {
        cursor: "pointer",
        bg: "blackAlpha.100"
    },
    _disabled: {
        cursor: "not-allowed"
    },
    rounded: "md"
};

const sizes = {
    touch: {
        button: "45px"
    },
    lg: {
        button: "40px"
    },
    md: {
        button: "32px"
    },
    sm: {
        button: "26px"
    }
};

export const CloseButton: React.FC<CloseButtonProps> = ({
    onClick,
    isDisabled,
    color,
    size = "md",
    closeButtonIcon,
    ...rest
}) => {
    // @ts-ignore
    const buttonSize = sizes[size] && sizes[size].button;

    return (
        <PseudoBox
            as="button"
            position="absolute"
            onClick={onClick}
            bg="transparent"
            border="none"
            disabled={isDisabled}
            size={buttonSize}
            {...styles}
            {...rest}
        >
            <Box as={closeButtonIcon ?? IoIosClose} size={"100%"} color={color} />
        </PseudoBox>
    );
};
