import styled from "styled-components";

type Props = {
    margin?: string;
};

export const Divider = styled.hr<Props>`
    background-color: ${props => props.theme.colors.lines};
    height: 1px;
    border: none;
    margin: ${props => props.margin};
`;
