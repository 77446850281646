import React, { forwardRef } from "react";

import { PseudoBox, PseudoBoxProps } from "Atoms";
import { commonStyles, useEditableContext } from "../Editable";

export type EditablePreviewProps = PseudoBoxProps;

export const EditablePreview: React.FC<EditablePreviewProps> = forwardRef((props, ref) => {
    const { getPreviewProps } = useEditableContext();

    //@ts-ignore //TODO: Not typescripted properly
    const previewProps = getPreviewProps(props, ref);

    return (
        <>
            {/** @ts-ignore */}
            <PseudoBox
                //@ts-ignore //TODO: Not typescripted properly
                ref={ref}
                as="span"
                cursor="text"
                w="full"
                //@ts-ignore //TODO: Not typescripted properly
                display="inline-block"
                {...commonStyles}
                {...previewProps}
            />
        </>
    );
});
