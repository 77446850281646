import React, { forwardRef } from "react";
import styled from "styled-components";

import { Box, BaseBoxProps, Flex, FlexProps } from "./";

type Props = {
    width: string;
    height: string;
    shadow: string;
    color: string;
    clickable: boolean;
};

/**
 * @deprecated This component is deprecated. Use NewCard instead.
 */
export const Card = styled.div<Partial<Props>>`
    padding: 0 0 32px;
    height: ${props => props.height || "100%"};
    box-shadow: ${props => props.shadow || "0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08)"};
    border-radius: 5px;
    width: ${props => props.width || "100%"};
    background-color: ${props => props.color || props.theme.colors.base};
`;

export const CardHeader = styled.header`
    padding: 32px;
`;

export const CardBody = styled.div`
    padding-right: 32px;
    padding-left: 32px;
`;

export const CardFieldset = styled.fieldset`
    position: relative;
    padding: 0;
    margin: 0;
    border: 0;

    & + & {
        margin-top: 24px;
    }

    &:nth-last-of-type(2) {
        margin-top: 32px;
    }

    &:last-of-type {
        text-align: center;
    }
`;

export type NewCardProps = FlexProps;

/**
 * This is the new card component to use in the application.
 */
export const NewCard: React.FC<NewCardProps> = forwardRef((props, ref) => {
    const { children, direction = "column", ...rest } = props;

    return (
        <Flex
            ref={ref}
            position="relative"
            minW={0}
            wordWrap="break-word"
            rounded="md"
            boxShadow="md"
            direction={direction}
            {...rest}
        >
            {children}
        </Flex>
    );
});

export type NewCardHeaderProps = BaseBoxProps;

export const NewCardHeader: React.FC<NewCardHeaderProps> = forwardRef((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Box ref={ref} p={5} {...rest}>
            {children}
        </Box>
    );
});

export type NewCardBodyProps = BaseBoxProps;

export const NewCardBody: React.FC<NewCardBodyProps> = forwardRef((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Box ref={ref} p={5} flex="1 1 0%" {...rest}>
            {children}
        </Box>
    );
});

export type NewCardActionsProps = FlexProps;

export const NewCardActions: React.FC<NewCardActionsProps> = forwardRef((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Flex ref={ref} p={5} {...rest}>
            {children}
        </Flex>
    );
});
