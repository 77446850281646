import React from "react";

import { BaseBoxProps, Box } from "../Box/Box";

export type FormLabelProps = {
    isRequired?: boolean;
} & BaseBoxProps;

export const RequiredIndicator: React.FC<{}> = () => {
    return <Box as="span" ml="1" color="red.500" children="*" />;
};

export const FormLabel: React.FC<FormLabelProps> = ({ children, isRequired = false, ...rest }) => {
    return (
        <Box
            fontSize="md"
            pr="12px"
            pb="4px"
            fontWeight="bold"
            textAlign="left"
            verticalAlign="middle"
            display="inline-block"
            as="label"
            {...rest}
        >
            {children}
            {isRequired && <RequiredIndicator />}
        </Box>
    );
};
