import React from "react";

import { Box, BaseBoxProps } from "../Box/Box";

export type NewDividerProps = {
    margin?: string;
    height?: string;
    bg?: string;
    vertical?: boolean;
    border?: string;
    opacity?: number;
} & BaseBoxProps;

const verticalStyles = {
    height: "auto",
    margin: "0 0.5rem",
    borderLeft: "1px"
};
const horizontalStyles = {
    width: "100%",
    borderBottom: "1px"
};

export const Divider: React.FC<NewDividerProps> = ({ color, bg, height = "1px", vertical = false, ...props }) => {
    const alignStyles = vertical ? verticalStyles : horizontalStyles;

    return (
        <Box
            as="hr"
            border={props.border !== undefined ? props.border : 0}
            borderStyle="solid"
            borderColor={color || "inherit"}
            borderWidth="1px"
            opacity={0.5}
            color={color}
            {...alignStyles}
            {...props}
        />
    );
};
