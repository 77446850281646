import styled from "styled-components";

type Props = {
    width?: string;
    maxWidth?: string;
    padding?: string;
}

export const Container = styled.div<Props>`
    width: ${props => props.width || "80%"};
    max-width: ${props => props.maxWidth || "1080px"};
    margin: 0 auto;
    padding: ${props => props.padding || "0"};
    position: relative;
    height: 100%;

    @media (max-width: 450px) {
        width: 100%;
    }
`;
