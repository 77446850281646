import React, { PropsWithChildren } from "react";
import { FiRefreshCcw } from "@react-icons/all-files/fi/FiRefreshCcw";

import { Button, Box, Header, Flex } from "Atoms";
import { ErrorBoundaryState, SENTRY_ERROR_TYPE as ErrorType } from "Types";

enum SENTRY_ERROR_BOUNDARY_CODE {
    GENERAL = "G",
    PUBLIC = "P",
    REDIRECT = "RI",
    RELOAD = "RE"
}

type ErrorState = {
    errorState: ErrorBoundaryState;
    reloadCurrentPage?: () => void;
};

type Props = {
    type: ErrorType;
    errorBoundaryState: ErrorState;
} & PropsWithChildren;

type GeneralBoundaryProps = Omit<Props, "type">;

export const ErrorBoundary: React.FC<Props> = ({ type, errorBoundaryState, children }) => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%">
            <Flex
                position="relative"
                boxShadow="lg"
                direction="column"
                borderColor="gray.400"
                borderStyle="solid"
                bg="white"
                rounded="md"
                borderWidth={1}
                borderRadius={8}
                p={6}
                mt={12}
                height="auto"
            >
                {type === ErrorType.GENERAL && <GeneralBoundary errorBoundaryState={errorBoundaryState} />}
                {type === ErrorType.PUBLIC && (
                    <PublicBoundary reloadCurrentPage={errorBoundaryState.reloadCurrentPage} />
                )}
                {type === ErrorType.REDIRECT && <RedirectBoundary errorBoundaryState={errorBoundaryState} />}
                {type === ErrorType.RELOAD && (
                    <ReloadBoundary reloadCurrentPage={errorBoundaryState.reloadCurrentPage} />
                )}
                {children}
            </Flex>
        </Flex>
    );
};

const GeneralBoundary: React.FC<GeneralBoundaryProps> = ({ errorBoundaryState }) => {
    const { errorState, reloadCurrentPage } = errorBoundaryState;
    const { componentStack, error } = errorState;
    const environmentIsDevelopment = process.env.NODE_ENV === "development";
    return (
        <>
            <Box>
                <Header as="h1">Något har gått snett...</Header>
                <Box as="p">Var god och återkom lite senare eller kontakta oss på support@qopla.com</Box>
            </Box>
            {environmentIsDevelopment && (
                <Box as="details" width="fit-content" maxWidth="60rem">
                    <Box as="summary">Detaljer</Box>
                    {error && error.toString()}
                    <br />
                    {componentStack}
                </Box>
            )}
            <Button
                marginLeft="auto"
                marginRight="auto"
                themeColor="green"
                variant="outline"
                rightIcon={FiRefreshCcw}
                onClick={reloadCurrentPage}
                mt={6}
            >
                Ladda om
            </Button>
            <Box position="absolute" right="10px" bottom="5px" fontSize="xs">
                {SENTRY_ERROR_BOUNDARY_CODE.GENERAL}
            </Box>
        </>
    );
};

export const PublicBoundary: React.FC<Pick<ErrorState, "reloadCurrentPage">> = ({ reloadCurrentPage }) => {
    return (
        <>
            <Header as="h1">Något har gått snett...</Header>
            <Box as="p">Ta ett djupt andetag medan vi gör vårt bästa för att fixa problemen.</Box>
            <Box as="p">Under tiden, var god och försök igen</Box>
            <Button
                marginLeft="auto"
                marginRight="auto"
                themeColor="green"
                variant="outline"
                rightIcon={FiRefreshCcw}
                onClick={reloadCurrentPage}
                mt={6}
            >
                Försök igen
            </Button>
            <Box position="absolute" right="10px" bottom="5px" fontSize="xs">
                {SENTRY_ERROR_BOUNDARY_CODE.PUBLIC}
            </Box>
        </>
    );
};

export const RedirectBoundary: React.FC<GeneralBoundaryProps> = ({ errorBoundaryState }) => {
    const { errorState, reloadCurrentPage } = errorBoundaryState;
    const { componentStack, error } = errorState;
    const environmentIsDevelopment = process.env.NODE_ENV === "development";
    return (
        <>
            <Box>
                <Header as="h1">Något har gått snett...</Header>
                <Box as="p">Var god och återkom lite senare eller kontakta oss på support@qopla.com</Box>
            </Box>
            {environmentIsDevelopment && (
                <Box as="details" width="fit-content" maxWidth="60rem">
                    <Box as="summary">Detaljer</Box>
                    {error && error.toString()}
                    <br />
                    {componentStack}
                </Box>
            )}
            <Button
                marginLeft="auto"
                marginRight="auto"
                themeColor="green"
                variant="outline"
                rightIcon={FiRefreshCcw}
                onClick={reloadCurrentPage}
                mt={6}
            >
                Ladda om
            </Button>
            <Box position="absolute" right="10px" bottom="5px" fontSize="xs">
                {SENTRY_ERROR_BOUNDARY_CODE.REDIRECT}
            </Box>
        </>
    );
};

export const ReloadBoundary: React.FC<Pick<ErrorState, "reloadCurrentPage">> = ({ reloadCurrentPage }) => {
    return (
        <>
            <Header as="h1">Något har gått snett...</Header>
            <Box as="p">Var god och återkom lite senare eller kontakta oss på support@qopla.com</Box>
            <Box as="p">Under tiden, var god och försök igen</Box>
            <Button
                marginLeft="auto"
                marginRight="auto"
                themeColor="green"
                variant="outline"
                rightIcon={FiRefreshCcw}
                onClick={reloadCurrentPage}
                mt={6}
            >
                Försök igen
            </Button>
            <Box position="absolute" right="10px" bottom="5px" fontSize="xs">
                {SENTRY_ERROR_BOUNDARY_CODE.RELOAD}
            </Box>
        </>
    );
};
