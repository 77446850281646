import React, { forwardRef } from "react";
import { ResponsiveValue } from "styled-system";

import { NewGrid as Grid } from "Atoms";
import { countToColumns } from "Utils";
import { GridProps } from "../Grid/Grid";

export type EasyGridProps = {
    columns: ResponsiveValue<number>;
} & GridProps;

/**
 * `EasyGrid` is a versatile grid layout component.
 * It's a wrapper over the standard `Grid` component that provides a simplified interface for defining grid columns.
 *
 * @component
 * @param {number} columns -       The number of columns for the grid. This prop is required.
 *                                 The value passed in is converted into a template string of equal fractions
 *                                 (e.g., passing in `3` would generate a template of `"1fr 1fr 1fr"`),
 *                                 using the `countToColumns` helper function.
 * @param {...any} props.rest - All other props are passed down to the underlying `Grid` component.
 *                              This allows you to utilize all of the features of the `Grid` component,
 *                              while getting the additional column-based layout capabilities of `EasyGrid`.
 * @example
 * // Usage
 * <EasyGrid columns={3} gap={5}>
 *     <div>Child 1</div>
 *     <div>Child 2</div>
 *     <div>Child 3</div>
 * </EasyGrid>
 * // Here, `EasyGrid` will render a grid with three columns and a gap of `5`.
 *
 *
 * @example
 * // Usage
 * <EasyGrid columns={[1, 2, 3]} gap={5}>
 *     <div>Child 1</div>
 *     <div>Child 2</div>
 *     <div>Child 3</div>
 * </EasyGrid>
 * // Here, `EasyGrid` will render a grid with responsive column based on our theme's breakpoints and a gap of `5`.
 */
export const EasyGrid: React.FC<EasyGridProps> = forwardRef((props, ref) => {
    const { columns, ...rest } = props;

    const templateColumns = countToColumns(columns);

    return <Grid ref={ref} templateColumns={templateColumns} {...rest} />;
});
