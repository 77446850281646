import React from "react";

import { Box, BaseBoxProps } from "../Box/Box";

// Creating shorthands from SS flex props
interface IGrid {
    templateAreas?: BaseBoxProps["gridTemplateAreas"];
    templateRows?: BaseBoxProps["gridTemplateRows"];
    templateColumns?: BaseBoxProps["gridTemplateColumns"];
    gap?: BaseBoxProps["gridGap"];
    area?: BaseBoxProps["gridArea"];
}

export type GridProps = IGrid & BaseBoxProps;

export const Grid: React.FC<GridProps> = React.forwardRef(
    ({ templateAreas, templateRows, templateColumns, gap, area, width = "100%", ...props }, ref) => (
        <Box
            display="grid"
            gridTemplateAreas={templateAreas}
            gridTemplateRows={templateRows}
            gridTemplateColumns={templateColumns}
            gridGap={gap}
            gridArea={area}
            width={width}
            {...props}
            ref={ref}
        />
    )
);
