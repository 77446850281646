import React from "react";

import { Text, TextProps } from "../Text/Text";

type EmojiProps = {
    symbol: string;
    label?: string;
} & TextProps;

export const Emoji: React.FC<EmojiProps> = ({ label, symbol, ...rest }) => (
    <Text
        as="span"
        className="emoji"
        role="img"
        aria-label={label ? label : ""}
        aria-hidden={label ? "false" : "true"}
        {...rest}
    >
        {symbol}
    </Text>
);
