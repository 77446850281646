import React from "react";
import AnimateHeight, { AnimateHeightProps } from "react-animate-height";

export type CollapsableProps = {
    /**
     * @deprecated please use `isOpen` instead
     */
    open?: boolean;
    isOpen?: boolean;
    height?: number;
} & Omit<AnimateHeightProps, "height">;

export const Collapsable: React.FC<CollapsableProps> = ({
    children,
    open,
    isOpen,
    height: startingHeight,
    ...rest
}) => {
    const _isOpen = open || isOpen;

    const hasStartingHeight = !!startingHeight;
    const expandedHeight = "auto";
    const collapsedHeight = 0;
    const _height = _isOpen ? expandedHeight : hasStartingHeight ? startingHeight : collapsedHeight;

    return (
        <AnimateHeight {...rest} height={_height}>
            {children}
        </AnimateHeight>
    );
};
