const baseProps = {
    border: "2px",
    borderStyle: "solid",
    rounded: "md",
    borderColor: "gray.200",
    transition: "background-color 120ms, box-shadow 250ms",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: "0",
    "aria-hidden": "true"
};

const interactionProps = ({ color }: IUseCheckboxStyle) => {
    return {
        color: "white",
        _checked: {
            bg: `${color}.500`,
            borderColor: `${color}.500`,
            color: undefined
        },
        _checkedAndDisabled: {
            borderColor: "gray.200",
            bg: "gray.200",
            color: "gray.500"
        },
        _disabled: {
            bg: "gray.100",
            borderColor: "gray.100"
        },
        _focus: {
            boxShadow: "outline"
        },
        _invalid: {
            borderColor: "red.500"
        }
    };
};

type IUseCheckboxStyle = {
    color: string;
    size: "sm" | "md" | "lg" | "xl" | "xxl";
    type: "radio" | "checkbox";
};

export const useCheckboxStyle = (props: IUseCheckboxStyle) => {
    const sizes = {
        xxl: 10,
        xl: 8,
        lg: 6,
        md: 5,
        sm: props.type === "radio" ? 4 : "auto"
    };

    return {
        ...baseProps,
        ...(props.size && { rounded: "md" }),
        ...interactionProps(props),
        size: sizes[props.size]
    };
};
